import React from "react";
import { Container, Icon, Text } from "@atoms";
import { Socials, FooterNewsletter } from "@molecules";
import { AppLink } from "@base";
import useFooterData from "@staticQueries/FooterQuery";
import useLang from "@hooks/useLang";

const Column = ({ text, url, children, i }) => {
  return (
    <li key={i}>
      <AppLink
        to={url}
        className="duration-400 mb-2 flex items-center transition hover:opacity-75 focus:opacity-75"
      >
        <Text variant="footerLinkHeader" className="text-white">
          {text}
        </Text>
      </AppLink>
      {children?.length > 0 && (
        <ul className="space-y-2">
          {children?.map((subLink, _i) => {
            const { text: _text, url: _url } = subLink;
            return (
              <li key={`subfooter-link-${i}--${_i + 1}`}>
                <AppLink
                  to={_url}
                  className="text-xs text-white transition duration-200 hover:opacity-75 focus:opacity-75"
                >
                  <Text variant="xs">{_text}</Text>
                </AppLink>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

const Footer = ({ pageLang }) => {
  const lang = useLang();

  const {
    footer,
    copyright,
    socials,
    download,
    acknowledge,
    charity,
    underFooter,
    newsletterCTA,
  } = useFooterData()[pageLang || lang || "en"];

  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative z-[350] bg-viridian py-10">
      <div className="texture-pattern-big absolute inset-0 z-0" />
      <Container variant="xl" className="relative z-10">
        {(acknowledge?.heading || acknowledge?.copy) && (
          <div className="mb-14 mt-4 flex flex-wrap items-start gap-8 border-2 border-2 border-white p-6 py-12 md:flex-nowrap">
            <Text
              tag="h3"
              className="leading-tighter font-magilio text-lg text-white md:text-1.5xl lg:text-h5"
            >
              {acknowledge?.heading}
            </Text>
            <Text
              variant="base"
              className="font-bold leading-tight text-white"
              dewidow
            >
              {acknowledge?.copy}
            </Text>
          </div>
        )}

        <div className="flex flex-wrap gap-8 md:flex-nowrap md:gap-14 lg:gap-20">
          <div className="flex max-w-2xs shrink-0 flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div>
                <AppLink to="/" className="mb-6">
                  <Icon
                    name={lang === "en" ? "logo" : "logoFr"}
                    className="w-28 text-white"
                  />
                </AppLink>
                <Text variant="xs" className="mt-4 text-white">
                  {`&copy; ${currentYear} ${copyright}`}
                </Text>
              </div>
              <Text variant="xs" className="text-white">
                {charity}
              </Text>
            </div>
            <Socials socials={socials} />
          </div>
          <div>
            <nav className="pb-8 md:pb-12">
              <ul className="flex flex-wrap justify-between gap-8">
                {footer?.map((col, i) => {
                  // eslint-disable-next-line react/no-array-index-key
                  return <Column {...col} key={i} i={i} />;
                })}
              </ul>
            </nav>

            <div className="flex w-full flex-col gap-4 border-t-3 border-white pt-8 md:flex-row md:gap-6 md:pt-12">
              <div id="newsletter" className="w-full md:w-2/3">
                <FooterNewsletter heading={newsletterCTA} />
              </div>

              {download?.heading && (
                <div className="flex w-full flex-col justify-center gap-5 text-center md:w-1/3">
                  <Text variant="xl" className="font-bold uppercase text-white">
                    {download?.heading}
                  </Text>
                  {download?.links && (
                    <ul className="flex w-full justify-center gap-4">
                      {download?.links?.map((subLink, _i) => {
                        const { text: _text, url: _url } = subLink;
                        return (
                          <li key={`download-link--${_i + 1}`}>
                            <AppLink
                              to={_url}
                              className="text-xs text-white transition duration-200 hover:opacity-75 focus:opacity-75"
                            >
                              <Text
                                variant="sm"
                                className="font-bold uppercase"
                              >
                                {_text}
                              </Text>
                            </AppLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {download?.emailSupport && (
                    <Text variant="xs" className="text-white">
                      {download?.emailSupport}
                    </Text>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10 flex items-center justify-between gap-8 border-t-3 py-4">
          <ul className="flex w-full flex-col items-start justify-between gap-3 sm:flex-row sm:gap-6">
            {underFooter?.map((item, i) => {
              const { text, url } = item;
              return (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className="w-full text-left sm:max-w-[8rem] sm:text-center"
                >
                  <AppLink to={url}>
                    <Text
                      variant="xs"
                      className="text-white transition duration-200 hover:opacity-75 focus:opacity-75"
                    >
                      {text}
                    </Text>
                  </AppLink>
                </li>
              );
            })}
          </ul>

          <div className="flex flex-col gap-8 sm:flex-row">
            <Icon
              name="partnershipCanada"
              className="w-24 shrink-0 text-white md:w-32"
            />
            <Icon name="ukDev" className="w-24 shrink-0 text-white md:w-32" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

Footer.defaultProps = {};

export default Footer;
